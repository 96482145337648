export function queryToObject(search) {
  const params = new URLSearchParams(search);
  const output = Array.from(params.keys()).reduce(
    (sum, value) => Object.assign({ [value]: params.get(value) }, sum),
    {}
  );
  return output;
}

const currencyMap = Object.create(null, {
  create: {
    value(currency) {
      return this[currency] = new Intl.NumberFormat(navigator.language, {
        currency,
        currencyDisplay: 'symbol',
        style: 'currency',
      });
    }
  },
});
currencyMap.create('USD');
currencyMap.default = currencyMap.USD;

export const formatCurrency = (amount, currency = 'default') => {
  let curr = currencyMap[currency] || currencyMap.create(currency);

  return curr.format(amount);
};
