import React from 'react';
import { connect } from 'react-redux';

import Button from 'Forms/Button/Button';
import MultiSelectDropdown from 'Forms/MultiSelectDropdown';
import Form from 'Forms/Form';
import Input from 'Forms/Input';

import styles from './index.module.css';


import { generateReport } from './actions';

export class Reports extends React.PureComponent {
  static defaultProps = {
    errors: [],
    restaurant: {},
    outlets: [],
  };

  state = {
    selectedOutlets: [],
    formErrors: {
      selectedOutlets: ''
    }
  }

  onSubmit = (event, data) => {
    const {
      dispatch,
      history,
      restaurant: { id : restaurant_id },
    } = this.props;

    const {
      selectedOutlets
    } = this.state

    if(selectedOutlets.length===0) {
      this.setState(()=>({
        formErrors: {
          selectedOutlets: 'Please select a location'
        }
      }))
      return;
    }
    else {
      this.setState(()=>({
        formErrors: {
          selectedOutlets: ''
        }
      }))
    }

    const outletIds = selectedOutlets.map((option)=> option.id)
    // data.set('outlet_ids', outletIds)

    return generateReport(data, restaurant_id, dispatch)
      .then(({ id: reportId }) => {
        history.push(`/manager/reports/${reportId}`)
      });
  }

  formatOptionLabel = ({location}) => {
    const {
      restaurant: {
        name: restaurantName,
      },
    } = this.props
    return `${restaurantName} ${location}`
  }

  onChange = selected => {
    this.setState(()=>({
      selectedOutlets: selected,
    }))
  }

  getOptionValue = ({id}) => {
    return id
  }

  render() {
    const {
      onSubmit,
      formatOptionLabel,
      onChange,
      getOptionValue,
      props: {
        errors,
        outlets,
        history,
        success,
      },
      state: {
        selectedOutlets,
        formErrors,
      }
    } = this;
    const isLoginRoute = history.location.pathname === '/';

    return (
      <main className={ !isLoginRoute ? "mt mb" : "full-width"}>
        <Form
          className={styles.Reports}
          format={Form.FORMATS.formdata}
          name="reportPicker"
          onSubmit={onSubmit}
        >
          <p className="mb">If you do not upload files, tip and schedule data will be used from the POS. If you do upload a file, that will be used instead of POS data.</p>

          <section
            className={styles.ReportBuilderSection}
          >
            <Input
              label="Report Start Date"
              name="start_date"
              placeholder="MM/DD/YYYY"
              required
              type="date"
            />

            <Input
              label="Report End Date"
              name="end_date"
              placeholder="MM/DD/YYYY"
              type="date"
            />

            <MultiSelectDropdown
              label="Location"
              name="outlet_ids"
              placeholder="location"
              options={outlets}
              value={selectedOutlets}
              getOptionValue={getOptionValue}
              formatOptionLabel={formatOptionLabel}
              onChange={onChange}
            />

            {formErrors.selectedOutlets.length !==0 &&
              <p
                className="error-message"
                key={`selectedOutlets-error`}
              >
                {formErrors.selectedOutlets}
              </p>
            }

            <Input
              className="mb"
              label="Generate report from POS"
              name="fetch_data_from_pos"
              type="checkbox"
              value={true}
            />
          </section>

          <section
            className={styles.ReportBuilderSection}
          >
            <Input
              className="mb"
              label="Staff Time Entries"
              name="shifts"
              type="file"
            />
            <Input
              className="mb"
              label="Transaction Data"
              name="transactions"
              type="file"
            />
          </section>

          {!!success &&
            <p className="success-message mt mb">Upload succeeded</p>
          }

          {!!errors.length &&
            <div className="errors mb">{
              errors.map(({ field, message }, i) => (
                <p
                  className="error-message"
                  key={`${field || message}-${i}`}
                >{message}</p>
              ))
            }</div>
          }

          <Button
            content="Generate Report"
            fullWidth
            type="submit"
            variant={Button.VARIANTS.primary}
          />
        </Form>
      </main>
    );
  }
}

export default connect(
  ({
    Reports: {
      errors,
    },
    User,
  }) => {
    const restaurants = User.restaurants || [{ outlets: [{}] }];
    const restaurant = { ...restaurants[0] }; // avoid mutating store data
    const { outlets } = restaurant;
    delete restaurant.outlets;

    return {
      errors,
      outlets,
      restaurant,
    };
  }
)(Reports);
