import React from 'react';
import { Route } from 'react-router-dom';
import PropTypes from 'prop-types';
import Redirect from './Redirect';

/* eslint-disable react/jsx-no-bind */
export const PrivateRoute = ({
  component: Component,
  isAuthenticated,
  userData,
  ...rest
}) => (
  <Route
    {...rest}
    render={props =>
      isAuthenticated ? (
        <Component {...props} userData={userData} isAuthenticated {...rest} />
      ) : (
        <Redirect />
      )
    }
  />
);

PrivateRoute.propTypes = {
  component: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
  isAuthenticated: PropTypes.bool,
  userData: PropTypes.object,
};
