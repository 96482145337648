import history from '../History';
import { replace } from 'lodash';

import { HOME_ROUTE } from 'App/Router/Constants';

// This util function is used to redirect the app to login page
export default () => {
  const { location } = window;
  const { origin, href } = location;
  const next = replace(href, origin, '');
  return history.push(HOME_ROUTE, { next });
};
