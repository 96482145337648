import ky from "ky";
import { API_URL, AUTH_TOKEN } from "Config";

const apiClient = ky.create({
  prefixUrl: API_URL,
  hooks: {
    beforeRequest: [
      (opts) => {
        const authToken = window.localStorage.getItem('authToken');
        if (authToken) {
          if (!opts.headers.get('Authorization')) opts.headers.set(
            'Authorization',
            `Token ${authToken}`
          );
        }
      }
    ],
    afterResponse: [
      async (rsp) => {
        if (!rsp.ok) try {
          rsp.payload = await rsp.json();
        } catch(e) { // response payload is empty or not json
          rsp.payload = {};
        }

        return rsp;
      },
    ],
  }
});

export default apiClient;
