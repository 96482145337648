import React from "react";
import classNames from "classnames";

import styles from "./Button.module.css";

const Button = ({
  content,
  className,
  fullWidth,
  is,
  variant,
  ...others
}) => React.createElement(
  is,
  {
    className: classNames(
      styles.Button,
      styles[variant],
      className,
      {
        [styles.fullWidth]: fullWidth,
      }
    ),
    ...others
  },
  content
);

Button.defaultProps = {
  is: 'button',
};

Button.VARIANTS = {
  danger: 'Danger',
  primary: 'Primary',
  success: 'Success',
  tertiary: 'Tertiary',
};

export default Button;
