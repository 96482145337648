import React from 'react';
import classNames from 'classnames';

import styles from './index.module.css';


export const InlineInput = ({
  className,
  label,
  name,
  required,
  type,
  ...others
}) => (
  <React.Fragment>
    <label
      className={classNames(
        styles.Label,
        styles.LabelFull,
        'mb-',
        {
          [styles.InputRequired]: required,
        }
      )}
      htmlFor={name}
    >{label}</label>

    <input
      {...others}
      className={classNames(
        styles.Input,
        className,
      )}
      name={name}
      required={required}
      type={type}
    />
  </React.Fragment>
);

const Input = ({
  className,
  label,
  name,
  required,
  type,
  ...others
}) => {
  const Label = (
    <span className={classNames(
      {
        required,
      }
    )}
    >{label}</span>
  );
  const Field = (
    <input
      {...others}
      className={classNames(
        styles.Input,
      )}
      name={name}
      required={required}
      type={type}
    />
  );

  return (
    <label
      className={classNames(
        styles.Label,
        className,
        'mt-',
        {
          [styles.Checkbox]: type === 'checkbox',
        }
      )}
    >
      {type === 'checkbox'
        ? (<>
            {Field}
            {Label}
          </>)
        : (<>
            {Label}
            {Field}
          </>)
      }
    </label>
  );
};

Input.defaultProps = {
  type: 'text',
};

export default Input;
