import React, { useEffect } from 'react';
import { NavLink, withRouter } from 'react-router-dom';
import style from './Header.module.css';
import logo from 'images/logo.png';

export function Header({ location, isAuthenticated, history, onLogout }) {
  useEffect(() => {
    if (!isAuthenticated) {
      history.push('/');
    }
  }, [isAuthenticated]);

  const rootSection = location.pathname.split('/')[1] || '';
  const isLoginRoute = location.pathname === '/';
  const onLogoutUser = event => {
    window.localStorage.removeItem('authToken');
    onLogout();
  };
  return !isLoginRoute ? (
    <header className={style.Header}>
      <NavLink className={style.HeaderLink} to={`/${rootSection}`}>
        <img className={style.HeaderLogo} src={logo} alt="Tipstant" />
      </NavLink>
      <div className={style.ButtonWrapper} onClick={onLogoutUser}>
        <a href="#" className={style.LogoutButton} label="Logout">
          Logout
        </a>
      </div>
    </header>
  ) : null;
}

export default withRouter(Header);
