import React from 'react';
import classNames from 'classnames';

import styles from './index.module.css';


const Dropdown = ({
  children,
  className,
  label,
  placeholder,
  required,
  ...others
}) => (
  <label
    className={classNames(
      styles.DropdownLabel,
      'mt mb',
    )}
  >
    <span className={classNames(
      {
        required,
      }
    )}
    >{label}</span>
    <div
      className={classNames(
        className,
        styles.Dropdown,
      )}
    >
      <select
        defaultValue=""
        required={required}
        {...others}
      >
        <option disabled value="">{placeholder}</option>
        {children}
      </select>
    </div>
  </label>
);

export default Dropdown;
