import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import Callout from 'Callout/Callout';
import Button from 'Forms/Button/Button';
import UploadWidget from 'Manager/UploadWidget';

import { uploadStaff } from './actions';
import history from '../../History';

const title = `
  Welcome to Tipstant,
  Create your daily and custom tip reports below.
`;

const StaffImport = ({ dispatch, errors, history, User: { restaurants } }) => {
  const [restaurant] = restaurants || [];
  const onSubmit = (formData, dispatch, reqParams) =>
    uploadStaff(formData, dispatch, reqParams).then(() =>
      setTimeout(() => history.push('/manager/reports'), 500),
    );

  const isLoginRoute = history.location.pathname === '/';

  return (
    <main className={!isLoginRoute ? 'mt mb' : 'full-width'}>
      <Callout title={title} />
      <UploadWidget
        errors={errors}
        inline
        label="Import staff from .CSV"
        name="staffFile"
        placeholder="staff.csv"
        requestParams={{ restaurant }}
        required
        uploadFunc={onSubmit}
      />

      <Button
        content="Daily Report"
        is={Link}
        to="/manager/reports"
        variant={Button.VARIANTS.primary}
      />
    </main>
  );
};

export default connect(({ Staff, User }) => {
  if (!Object.keys(User).length)
    User = {
      restaurants: [
        {
          outlets: [{}],
        },
      ],
    };

  return {
    ...Staff,
    User,
  };
})(StaffImport);
