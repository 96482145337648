import React from 'react';
import { connect } from 'react-redux';
import { Redirect, Route, Router, Switch, useLocation } from 'react-router-dom';
import history from '../History';

import Header from 'Header/Header';

import TipsPaymentConfirmation from 'Manager/Reports/Tips/PaymentConfirmation';
import StaffImport from 'Manager/StaffImport';
import Reports from 'Manager/Reports';
import Report from 'Manager/Reports/Report';
import TipReport from 'Manager/Reports/Tips';
import Login from 'Login';
import { PrivateRoute } from './Router/PrivateRoute';

import CashTips from 'Staff/CashTips';

import { getUser, logoutUser } from './actions';

import './index.css';

export class App extends React.PureComponent {
  componentDidMount() {
    getUser(this.props.dispatch);
  }

  onLogout = () => {
    logoutUser(this.props.dispatch);
  };

  render() {
    // todo: check if this is the correct way to get the location outside the router.
    const isLoginRoute = history.location.pathname === '/';
    const {
      isAuthenticated: { isAuthenticated },
      User: userData,
    } = this.props;
    if (isAuthenticated === undefined) {
      // if we are evaluating is authenticated we shouldn't go for
      // the rendering we should show a loading state probably
      return null;
    }

    return (
      <Router history={history}>
        <Header isAuthenticated={isAuthenticated} onLogout={this.onLogout} />
        <Switch>
          <Route
            exact
            path="/"
            isAuthenticated={isAuthenticated}
            userData={userData}
            component={Login}
          />
          <PrivateRoute
            exact
            path="/manager"
            component={StaffImport}
            isAuthenticated={isAuthenticated}
            userData={userData}
          />
          <PrivateRoute
            exact
            path="/manager/reports"
            component={Reports}
            isAuthenticated={isAuthenticated}
            userData={userData}
          />
          <PrivateRoute
            exact
            isAuthenticated={isAuthenticated}
            userData={userData}
            path="/manager/reports/:id"
            component={routeProps => (
              <Report
                render={props => (
                  <main className={!isLoginRoute ? 'mt mb' : 'full-width'}>
                    <TipReport {...props} {...routeProps} />
                  </main>
                )}
                {...routeProps}
              />
            )}
          />

          <PrivateRoute
            exact
            path="/manager/reports/:id/confirmation"
            component={routeProps => (
              <Report
                render={props => (
                  <main className={!isLoginRoute ? 'mt mb' : 'full-width'}>
                    <TipsPaymentConfirmation {...props} {...routeProps} />
                  </main>
                )}
                {...routeProps}
              />
            )}
          />
          <PrivateRoute
            exact
            component={CashTips}
            isAuthenticated={isAuthenticated}
            userData={userData}
            path="/staff/tips/cash"
          />
        </Switch>
      </Router>
    );
  }
}

export default connect(({ User, isAuthenticated }) => {
  if (!Object.keys(User).length)
    User = {
      restaurants: [
        {
          outlets: [{}],
        },
      ],
    };
  return {
    User,
    isAuthenticated,
  };
})(App);
