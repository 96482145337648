import apiClient from '../../apiClient';

export const CASH_TIP_RECORDED = 'CASH_TIP_RECORDED';
export const CASH_TIP_RECORDING_FAILED = 'CASH_TIP_RECORDING_FAILED';

export function recordCashTip(tip, outletId) {
  return apiClient
    .post(`outlets/${outletId}/tips/cash`, {
      json: {
        tip,
      },
    })
    .json();
}

export function getCashTipHistory(outletId, date) {
  const url = `outlets/${outletId}/tips/cash`;
  const searchParams = { paid_date: date };
  return apiClient
    .get(url, { searchParams })
    .json();
}

export function getCashTipHistoryForToday(outletId) {
  const todaysDate = new Date().toISOString().split('T')[0];
  return getCashTipHistory(outletId, todaysDate);
}
