import React from 'react';
import { connect } from 'react-redux';
import styles from './style.module.css';
import tipstantLogo from '../images/tipstant-bw-circle.png';

import Form from 'Forms/Form';
import Input from 'Forms/Input';
import Button from '../Forms/Button/Button';
import { login } from './actions';
import { getUser } from '../App/actions';

export class Login extends React.PureComponent {
  static defaultProps = {
    errors: [],
    restaurant: {},
    isAuthenticated: false,
    outlets: [{}],
  };

  componentDidMount() {
    const {
      isAuthenticated: { isAuthenticated },
      history,
    } = this.props;
    if (isAuthenticated) {
      history.push(`/manager`);
    }
  }

  componentDidUpdate(prevProps) {
    const {
      isAuthenticated: { isAuthenticated: isPrevAuthenticated },
    } = prevProps;
    const {
      isAuthenticated: { isAuthenticated },
      history,
    } = this.props;
    if (isAuthenticated && isPrevAuthenticated !== isAuthenticated) {
      history.push(`/manager`);
    }
  }

  onSubmit = (event, data) => {
    const { dispatch, history } = this.props;

    return login(data, dispatch).then(() => {
      setTimeout(function() {
        // history.location.reload();
      }, 1000);
    });
  };

  render() {
    const { onSubmit } = this;

    return (
      <div className={styles.PageWrapper}>
        <div className={styles.HeroImage}>
          <img className={styles.HeroLogo} src={tipstantLogo} alt="Tipstant" />
        </div>
        <div className={styles.Wrapper}>
          <div className={styles.FormWrapper}>
            <Form
              format={Form.FORMATS.json}
              name="loginForm"
              onSubmit={onSubmit}
            >
              <p className="mb">Welcome to Tipstant</p>

              <section>
                <Input
                  label="Enter your email"
                  name="email"
                  placeholder="Email"
                  required
                  type="email"
                />

                <Input
                  label="Enter your password"
                  name="password"
                  placeholder="Password"
                  required
                  type="password"
                />
              </section>
              <section className={styles.ButtonWrapper}>
                <Button
                  content="Login"
                  fullWidth
                  type="submit"
                  variant={Button.VARIANTS.primary}
                />
              </section>
            </Form>
          </div>
        </div>
      </div>
    );
  }
}

export default connect(({ User, isAuthenticated }) => {
  if (!Object.keys(User).length)
    User = {
      restaurants: [
        {
          outlets: [{}],
        },
      ],
    };

  return {
    User,
    isAuthenticated,
  };
})(Login);
