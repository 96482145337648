import React, { useState } from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';

import Button from 'Forms/Button/Button';
import Input, { InlineInput } from 'Forms/Input';
import Form from 'Forms/Form';


import WidgetStyles from './index.module.css';


export const UploadWidget = ({
  dispatch,
  errors,
  inline,
  label,
  name,
  placeholder,
  requestParams,
  required,
  uploadFunc,
}) => {
  const [file, setFile] = useState(null);
  const [filename, setFilename] = useState('Import csv');
  const [success, setSuccess] = useState(false);

  const onChange = e => {
    setFile(e.target.files[0]);
    setFilename(e.target.files[0].name);
  };

  const onSubmit = (event) => {
    event.persist();

    const formData = new FormData();
    formData.append('name', name);
    formData.append('file', file);
    formData.append('filename', filename);
    uploadFunc(formData, dispatch, requestParams)
      .then(() => {
        setSuccess(true);
        event.target.reset();
      })
      .catch(() => setSuccess(false));
  };

  const onReset = e => {
    setFile(null);
    setFilename('');
  };

  const InputField = inline
    ? InlineInput
    : Input;

  const inputName = `${name}_file`;

  return (
    <div className="mt mb">
      <Form
        inline
        name={name}
        onSubmit={onSubmit}
        onReset={onReset}
      >
        <InputField
          className={classNames(
            {
              'mt': !inline,
              [WidgetStyles.InlineInput]: inline,
            }
          )}
          id={inputName}
          label={label}
          name={inputName}
          onChange={onChange}
          placeholder={placeholder}
          required={required}
          type="file"
        />
        <Button
          className={classNames(
            WidgetStyles.InlineButton,
            {
              'mt mb': !inline,
            }
          )}
          content="Upload"
          variant={
            inline
              ? Button.VARIANTS.tertiary
              : Button.VARIANTS.primary
          }
          type="submit"
        />
      </Form>

      {!!success &&
        <p className="success-message mt mb">Upload succeeded</p>
      }

      {!!errors &&
        <div className="errors mt mb">{
          errors.map(({ field, message }) => (
            <p
              className="error-message"
              key={field}
            >{message}</p>
          ))
        }</div>
      }
    </div>
  );
};

UploadWidget.defaultProps = {
  inline: false,
  placeholder: 'filename.csv',
};

export default connect(state => ({ ...state }))(UploadWidget);
