import React from 'react';
import classNames from 'classnames';

import styles from './index.module.css';


const FORMATS = {
  formdata: 'formdata',
  json: 'json',
};

export default class Form extends React.PureComponent {
  static defaultProps = {
    format: FORMATS.json,
  };

  static FORMATS = FORMATS;

  createFormData = (form) => new FormData(form);

  createJSON = (fields) => fields
    .reduce((data, { files, name, parentElement, tagName, value }) => {
      switch (tagName) {
        case 'INPUT':
          if (files) value = files[0];
        case 'SELECT': // eslint-disable-line no-fallthrough
        case 'TEXTAREA':
          const grandparent = parentElement.parentElement;

          if (grandparent.tagName === 'FIELDSET') {
            data[grandparent.name][name] = value;
          } else {
            data[name] = value;
          }

          break;
        case 'FIELDSET':
          data[name] = {};
          break;
        default: break;
      }

      return data;
    }, {});

  onSubmit = (event) => {
    event.preventDefault();

    const {
      createFormData,
      createJSON,
      props: {
        format,
        onSubmit,
      },
    } = this;

    let data;

    switch (format) {
      case 'formdata':
        data = createFormData(event.target);
        break;
      default:
        data = createJSON([...event.target.elements]);
        break;
    }

    return onSubmit(event, data);
  };

  render() {
    const {
      onSubmit,
      props: {
        children,
        className,
        inline,
        ...others
      },
    } = this;

    return (
      <form
        {...others}
        className={classNames(
          styles.Form,
          className,
          {
            [styles.InlineForm]: inline,
          }
        )}
        onSubmit={onSubmit}
      >
        {children}
      </form>
    );
  }
}
