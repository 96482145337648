import React from 'react';
import Table from '../../Table';

const dateFormatter = new Intl.DateTimeFormat([], {
  year: 'numeric',
  month: 'numeric',
  day: 'numeric',
  hour: '2-digit',
  minute: '2-digit',
  second: '2-digit',
}).format;

const formatDate = datetime => dateFormatter(new Date(datetime));

const convertDollarsToCents = amount => amount * 100;
const convertCentsToDollars = amount => amount / 100;

const DisplayCashTip = ({ data }) => {
  let totalTips = 0;
  return (
    <Table>
      <thead>
        <tr>
          <th data-type="numeric">Time</th>
          <th data-type="numeric">Amount</th>
        </tr>
      </thead>

      <tbody>
        {data.map(({ id, tip, paid_datetime }) => {
          totalTips += parseInt(convertDollarsToCents(tip));
          return (
            <tr key={id}>
              <td data-type="numeric">{formatDate(paid_datetime)}</td>
              <td data-type="numeric">{tip}</td>
            </tr>
          );
        })}
      </tbody>
      <tfoot>
        <tr>
          <th colSpan="2" data-type="numeric">
            Total ${convertCentsToDollars(totalTips)}
          </th>
        </tr>
      </tfoot>
    </Table>
  );
};

export default DisplayCashTip;
