import {
  addReducer,
} from '../../store';
import { queryToObject } from '../../Helpers';
import apiClient from '../../apiClient';

import { createJSDate } from './helpers';


export const REPORT_GENERATED = 'REPORT_GENERATED';
export const REPORT_FAILURE = 'REPORT_FAILURE';

export function generateReport(body, resturantId, dispatch) {
  return apiClient
    .post(`restaurants/${resturantId}/reports`, {
      body,
    })
    .json()
    .then((payload) => {
      dispatch({
        payload,
        type: REPORT_GENERATED,
      });

      return payload;
    })
    .catch(({ response: { payload: { errors }} }) => {
      throw dispatch({
        payload: { errors },
        type: REPORT_FAILURE,
      });
    });
}

export const REPORT_RETRIEVED = 'REPORT_RETRIEVED';

const failureMessage = (id) => `Sorry, something went wrong and this report has been paused. Cite ${id} to reference this issue.`;

const fetchReport = (id, dispatch, { id: intervalId }) => {
  return apiClient
    .get(`reports/${id}`)
    .json()
    .then((data) => {
      const { status } = data;

      const report = {
        data,
        start_date: createJSDate(data.start_date),
        end_date: createJSDate(data.end_date),
      };
      let type = REPORT_RETRIEVED;

      if (status !== 'in_progress') {
        clearInterval(intervalId);
      }

      if (status.includes('error')) {
        report.errors = [{
          message: failureMessage(id),
        }];
        type = REPORT_FAILURE;
      }

      dispatch({
        payload: { [id]: report },
        type,
      });

      return data;
    });
};

export const getReport = ({ id }, dispatch) => {
  const interval = { id: 0 }; // must be an object to pass-by-ref

  return fetchReport(id, dispatch, interval)
    .then(({ status }) => {
      if (status === 'in_progress') {
        interval.id = setInterval(
          fetchReport,
          1000,
          id,
          dispatch,
          interval,
        );
      }
    });
};

const REPORT_DELETED = 'REPORT_DELETED';

export const deleteReport = ({ id }, dispatch) => {
  return apiClient
    .delete(`reports/${id}`)
    .then(() => dispatch({
      payload: { [id]: undefined },
      type: REPORT_DELETED,
    }))
    .catch(({ response: { payload: { errors }} }) => {
      throw dispatch({
        payload: { [id]: { errors } },
        type: REPORT_FAILURE,
      });
    });
};

export const TIPS_APPROVED = 'TIPS_APPROVED';

export const approveTips = ({ id }, dispatch) => {
  return apiClient
    .patch(`reports/${id}`, {
      json: { status: 'approved' },
    })
    .json()
    .then((data) => dispatch({
      payload: {
        [id]: {
          data,
          date: createJSDate(data.date),
        },
      },
      type: TIPS_APPROVED,
    }));
};

export const TIPS_PAID = 'TIPS_PAID';
export const TIPS_PAYOUT_ERROR = 'TIPS_PAYOUT_ERROR';

export const payTips = ({ id }, dispatch) => {
  return apiClient
    .post(`reports/${id}/payments`)
    .json()
    .then((result) => dispatch({
      payload: { [id]: { ...result } },
      type: TIPS_PAID,
    }))
    .catch(({ response: { payload: { errors }} }) => {
      throw dispatch({
        payload: { [id]: { errors } },
        type: TIPS_PAYOUT_ERROR,
      });
    });
};

export const TIPS_SUMMARY_FILE_RETRIEVED = 'TIPS_SUMMARY_FILE_RETRIEVED';

export const getTipsSummaryFile = ({ id }, dispatch) => {
  return apiClient
    .get(`reports/${id}/download`)
    .then(async (rsp) => {
      const contentDisposition = rsp.headers.get('Content-Disposition') || '';
      const file = await rsp.blob();

      file.name = contentDisposition.includes('filename')
        ? queryToObject(
          contentDisposition
            .split('; ')
          [1] // eslint-disable-line no-unexpected-multiline
        )
          .filename
          .replace(/["']/g, '')
        : `${id}.csv`;

      return file;
    })
    .then((csv) => {
      dispatch({
        payload: { [id]: { csv } },
        type: TIPS_SUMMARY_FILE_RETRIEVED,
      });

      return csv;
    });
};

const actionHandlersMap = {
  [REPORT_GENERATED]: null,
  [REPORT_DELETED]: null,
  [REPORT_FAILURE]: null,
  [REPORT_RETRIEVED]: null,
  [TIPS_APPROVED]: null,
  [TIPS_PAID]: null,
  [TIPS_PAYOUT_ERROR]: null,
  [TIPS_SUMMARY_FILE_RETRIEVED]: null,
};

addReducer('Reports', actionHandlersMap);
