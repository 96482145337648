import React from 'react';
import { Link } from 'react-router-dom';

import Callout from 'Callout/Callout';
import Button from 'Forms/Button/Button';

import { formatCurrency } from 'Helpers';


export default function PaymentConfirmation({
  data: {
    employee_count: count,
    total_tip_amount: total,
  },
}) {
  return (
    <React.Fragment>
      <Callout title={`${formatCurrency(total)} was successfully paid to ${count} of your staff. They'll receive their payment shortly.`} />
      <menu className="mt">
        <Button
          content="Close"
          is={Link}
          to="/manager/reports"
          variant={Button.VARIANTS.tertiary}
        />
      </menu>
    </React.Fragment>
  );
}

PaymentConfirmation.defaultProps = {
  data: {
    employee_count: 0,
    total_tip_amount: 0,
  },
};
