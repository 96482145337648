import React from 'react';
import { Link } from 'react-router-dom';
import Lottie from 'lottie-react-web';
import classNames from 'classnames';
import { saveAs } from 'file-saver';

import Button from 'Forms/Button/Button';
import Modal from 'Modal';
import Table from '../../../Table'; // 🤯 breaks as an absolute import. see webpack/webpack#5073
import animation from '../../../tipstant-loader.json';

import { formatCurrency } from 'Helpers';

import {
  approveTips,
  deleteReport,
  getTipsSummaryFile,
  payTips,
} from '../actions';


const MILLISECONDS_PER_HOUR = 3600000;
const paidStatuses = [
  'payment_in_progress',
  'paid',
];

let formatHours;

try {
  formatHours = new Intl.NumberFormat([], {
    maximumFractionDigits: 2,
    minimumFractionDigits: 2,
    style: 'unit',
    unit: 'hour',
  }).format;
} catch (e) {
  formatHours = new Intl.NumberFormat([], {
    maximumFractionDigits: 2,
    minimumFractionDigits: 2,
    style: 'decimal',
    unit: 'hour',
  }).format;
}

export default class TipReport extends React.PureComponent {
  static defaultProps = {
    data: {
      status: 'in_progress',
      tips: new Array(0),
    },
    date: '',
  };

  static formatTime = new Intl.DateTimeFormat([], {
    hour: '2-digit',
    minute: '2-digit',
  }).format;

  static formatHours = formatHours;

  state = {
    deletionModalOpen: false,
    payoutModalOpen: false,
  };

  triggerApproval = () => {
    const {
      dispatch,
      match: {
        params,
      },
    } = this.props;

    return approveTips(params, dispatch)
      .then(() => this.setState({
        payoutModalOpen: true,
      }));
  };

  triggerCSVDownload = () => {
    const {
      csv,
      dispatch,
      match: { params },
    } = this.props;

    const promise = !!csv
      ? Promise.resolve(csv)
      : getTipsSummaryFile(params, dispatch);

    return promise
      .then((csv) => saveAs(csv, csv.name))
  };

  triggerDeletion = () => {
    const {
      dispatch,
      history,
      match: {
        params,
      },
    } = this.props;

    return deleteReport(params, dispatch)
      .then(() => history.push('/manager/reports'))
      .catch(() => this.setState({
        deletionModalOpen: false,
      }));
  };

  triggerPayment = () => {
    const {
      dispatch,
      history,
      match: {
        params,
      },
    } = this.props;

    return payTips(params, dispatch)
      .then(() => history.push(`/manager/reports/${params.id}/confirmation`))
      .catch(() => this.setState({
        payoutModalOpen: false,
      }));
  };

  render() {
    const {
      // triggerApproval, this needs to be tested after out multirest feature
      triggerCSVDownload,
      triggerDeletion,
      triggerPayment,
      props: {
        data,
        date,
        errors,
      },
      state: {
        deletionModalOpen,
        payoutModalOpen,
      },
    } = this;
    const {
      formatHours,
      formatTime,
    } = TipReport;
    const isPaid = paidStatuses.includes(data.status);
    const isLoading = data.status === 'in_progress';
    const hasError = !isLoading && !!errors;
    const noData = !isLoading && !data.tips.length;
    const isDaily = data.end_date === null || data.start_date === data.end_date;

    const declineProps = (isPaid || noData)
      ? {
        content: 'Close',
        is: Link,
        to: '/manager/reports',
        variant: Button.VARIANTS.tertiary,
      }
      : {
        content: 'Delete',
        onClick: () => {
          this.triggerCSVDownload();
          this.setState({ deletionModalOpen: true });
        },
        variant: Button.VARIANTS.primary,
      };

    return (
      <React.Fragment>
        {!!date && (
          <h1>
            Report for{' '}
            {new Date(date).toLocaleDateString([], {
              day: 'numeric',
              month: 'long',
              year: 'numeric',
            })}
          </h1>
        )}

        <Table>
          <thead>
            <tr>
              <th>full name</th>
              {isDaily &&
              <>
                <th>outlet location</th>
                <th>role</th>
                <th data-type="numeric">points</th>
                <th data-type="numeric">start</th>
                <th data-type="numeric">end</th>
                <th data-type="numeric">duration</th>
              </>
              }
              <th data-type="numeric">tips due</th>
            </tr>
          </thead>

          <tbody>
            {data.tips.map(
              (tip) => {
                const outlet = tip.restaurant_outlet.location;
                const start = tip.shift && new Date(tip.shift.in_datetime);
                const end = tip.shift && tip.shift.out_datetime && new Date(tip.shift.out_datetime);
                const { first_name, last_name } = tip.shift ? tip.shift.employee : tip.employee;
                const { title, points_for_tip_calculation } = tip.shift ? tip.shift.job : {};
                const { id } = tip;

                return (
                  <tr key={id}>
                    <td>{`${first_name} ${last_name}`}</td>
                    {isDaily &&
                    <>
                      <td>{outlet}</td>
                      <td>{title}</td>
                      <td data-type="numeric">{points_for_tip_calculation}</td>
                      <td data-type="numeric">{formatTime(start)}</td>
                      <td
                        className={classNames({ 'error-message': !end })}
                        data-type="numeric"
                      >
                        {end && formatTime(end)}
                      </td>
                      <td data-type="numeric">
                        {formatHours((end - start) / MILLISECONDS_PER_HOUR)}
                      </td>
                    </>
                    }
                    <td data-type="numeric">{formatCurrency(tip.tips_owed)}</td>
                  </tr>
                );
              },
            )}
          </tbody>

          <tfoot>
            <tr>
              <th
                colSpan={isDaily ? 7 : 1}
                data-type="numeric"
              >Total</th>
              <td
                data-type="numeric"
              >{formatCurrency(data.total_tip_amount || 0)}</td>
            </tr>
          </tfoot>
        </Table>

        {hasError
          ? (
            <div className="errors mt mb">
              {errors.map(({ message }, i) => (
                <p className="error-message" key={i}>
                  {message}
                </p>
              ))}
            </div>
          )
          : noData && (
            <p>No data.</p>
          )
        }

        {isLoading ? (
          <>
            <Lottie
              style={{ width: 50 }}
              options={{
                animationData: animation,
              }}
            />
            <p className="tac mt">Generating your report</p>
          </>
        ) : (
          <>
            <Button {...declineProps} name="decline" />

            <Button
              content="Download .CSV report"
              disabled={hasError}
              name="saveCSV"
              onClick={triggerCSVDownload}
              variant={Button.VARIANTS.tertiary}
            />
          </>
        )}

        <Modal
          backdropped
          open={deletionModalOpen}
        >
          <h3>Do wish to delete this report?</h3>
          <p>
            This will also delete the associated shift and transaction data.
          </p>

          <menu>
            <Button
              content="No"
              name="abortDeletelation"
              onClick={() => this.setState({ deletionModalOpen: false })}
              variant={Button.VARIANTS.tertiary}
            />

            <Button
              content="Yes"
              name="triggerDeletion"
              onClick={triggerDeletion}
              variant={Button.VARIANTS.danger}
            />
          </menu>
        </Modal>

        <Modal
          backdropped
          open={payoutModalOpen}
        >
          <h3>Do you wish to pay tips?</h3>
          <p>This is irreversable</p>

          <menu>
            <Button
              content="Cancel"
              name="abortPayment"
              onClick={() => this.setState({ payoutModalOpen: false })}
              variant={Button.VARIANTS.tertiary}
            />

            <Button
              content="Pay tips now"
              name="triggerPayment"
              onClick={triggerPayment}
            />
          </menu>
        </Modal>
      </React.Fragment>
    );
  }
}
