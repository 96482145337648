import React from 'react';

import styles from './style.module.css';

const Table = ({ children, ...others }) => (
  <table
    className={styles.Table}
    {...others}
  >
    {children}
  </table>
);

export default Table;
