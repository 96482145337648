import apiClient from '../apiClient';
import {
  USER_AUTHENTICATED_FALIURE,
  USER_AUTHENTICATED_SUCCESS,
  USER_FETCH_FAILURE,
  USER_LOADED,
} from '../App/actions';
import { addReducer } from '../store';

export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FALIURE = 'LOGIN_FALIURE';

export function login(body, dispatch) {
  return apiClient
    .post(`auth/login`, {
      json: body,
    })
    .json()
    .then(response => {
      const { auth_token: authToken } = response;
      window.localStorage.setItem('authToken', authToken);
      dispatch({
        payload: {
          ...response,
        },
        type: USER_LOADED,
      });
      dispatch({
        payload: {
          ...response,
        },
        type: LOGIN_SUCCESS,
      });
      dispatch({
        payload: { isAuthenticated: true },
        type: USER_AUTHENTICATED_SUCCESS,
      });
    })
    .catch(({ response: { payload: { errors } } }) => {
      dispatch({
        payload: { isAuthenticated: false },
        type: USER_AUTHENTICATED_FALIURE,
      });

      throw dispatch({
        payload: { errors },
        type: LOGIN_FALIURE,
      });
    });
}

const actionHandlersMap = {
  [USER_LOADED]: null,
  [USER_FETCH_FAILURE]: null,
};

const isAuthenticatedActionHandlersMap = {
  [USER_AUTHENTICATED_SUCCESS]: null,
  [USER_AUTHENTICATED_FALIURE]: null,
};

addReducer('User', actionHandlersMap);
addReducer('isAuthenticated', isAuthenticatedActionHandlersMap);
