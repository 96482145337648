import React from 'react';
import { connect } from 'react-redux';

import {
  getReport,
} from './actions';

export class Report extends React.PureComponent {
  componentDidMount() {
    const {
      dispatch,
      match: {
        params,
      },
    } = this.props;

    getReport(params, dispatch);
  }

  render() {
    const {
      render,
      ...props
    } = this.props;
    const {
      match: { params : { id } },
    } = this.props;
    const report = props[id];
    delete props[id];

    return render({
      ...props,
      ...report,
    });
  }
}

export default connect(
  ({ Reports }) => ({ ...Reports }),
  null,
  (reports, dispatchProps, ownProps) => {
    const { id } = ownProps.match.params;
    const report = reports[id];

    return {
      ...ownProps,
      ...dispatchProps,
      [id]: report,
    };
  },
)(Report);
