import {
  applyMiddleware,
  combineReducers,
  compose,
  createStore,
} from "redux";
import thunk from "redux-thunk";
import _merge from 'lodash.merge';

const staticReducers = combineReducers({
  '': (state, action) => ({...state}),
});

const store = createStore(
  staticReducers,
  compose(
    applyMiddleware(thunk),
    typeof window === "object" &&
      typeof window.__REDUX_DEVTOOLS_EXTENSION__ !== "undefined"
      ? window.__REDUX_DEVTOOLS_EXTENSION__()
      : f => f
  )
);


// https://redux.js.org/recipes/code-splitting

const addNewStateToOld = (oldState, newState) => _merge({}, oldState, newState);
const passthruHandler = (oldState) => oldState;
const asyncReducers = {};

/**
 * @function addReducer Asynchronously / modularly add a reducer and actions + handlers to the Store.
 * @param  {String} key The top-level key name for the Redux store
 * @param  {Object} actionHandlersMap A set of key-value pairs mapping an action type to a handler. To use a generic
 * handler (ex simply merge old and new states), set the value to something falsy (ex `null`)
 * @param  {Function} reducer A reducer to add to the store for the specified `key`. To use a generic reducer that
 * simply matches the handlers, do not supply this argument.
 * @return {void}
 * @example
 * addReducer(
 *  'Groups',
 *  {
 *    GROUPS_LIST_LOADED: (state) => ({
 *      ...state,
 *      loaded: true,
 *    }),
 *  }
 * )
 * @example addReducer('Users', { USERS_LIST_LOADED: null })
 */
export const addReducer = (key, actionHandlersMap, reducer) => {
  const genericReducer = (state = {}, { payload, type }) => {
    const handler = actionHandlersMap[type] || passthruHandler;

    return handler(state, payload);
  };

  reducer = reducer || genericReducer;

  for (let name in actionHandlersMap) {
    actionHandlersMap[name] = actionHandlersMap[name] || addNewStateToOld;
  }

  asyncReducers[key] = reducer;

  store.replaceReducer(combineReducers({
    ...staticReducers,
    ...asyncReducers,
  }))
};

export default store;
