import apiClient from '../apiClient';
import { addReducer } from '../store';

export const USER_LOADED = 'USER_LOADED';
export const USER_FETCH_FAILURE = 'USER_FETCH_FAILURE';
export const USER_AUTHENTICATED_SUCCESS = 'USER_AUTHENTICATED_SUCCESS';
export const USER_AUTHENTICATED_FALIURE = 'USER_AUTHENTICATED_FALIURE';

export const getUser = dispatch => {
  return apiClient
    .get('me')
    .json()
    .then(payload => {
      dispatch({
        payload,
        type: USER_LOADED,
      });
      dispatch({
        payload: { isAuthenticated: true },
        type: USER_AUTHENTICATED_SUCCESS,
      });
    })
    .catch(({ response: { payload: { errors } } }) => {
      // todo wrap this in 401
      window.localStorage.removeItem('authToken');

      dispatch({
        payload: { isAuthenticated: false },
        type: USER_AUTHENTICATED_FALIURE,
      });
      throw dispatch({
        payload: { errors },
        type: USER_FETCH_FAILURE,
      });
    });
};

export const logoutUser = dispatch => {
  dispatch({
    payload: { isAuthenticated: false },
    type: USER_AUTHENTICATED_FALIURE,
  });
};

const actionHandlersMap = {
  [USER_LOADED]: null,
  [USER_FETCH_FAILURE]: null,
};

const isAuthenticatedActionHandlersMap = {
  [USER_AUTHENTICATED_SUCCESS]: null,
  [USER_AUTHENTICATED_FALIURE]: null,
};

addReducer('User', actionHandlersMap);
addReducer('isAuthenticated', isAuthenticatedActionHandlersMap);
