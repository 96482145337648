import React, { useState } from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';
import FormStyles from 'Forms/Form/index.module.css';
import InputStyles from 'Forms/Input/index.module.css';
import Input from 'Forms/Input';

import DisplayCashTip from './displayCashTip';
import Button from 'Forms/Button/Button';
import Dropdown from 'Forms/Dropdown';
import Form from 'Forms/Form';
import ButtonStyles from 'Forms/Button/Button.module.css';
import { recordCashTip, getCashTipHistoryForToday } from './actions';
import CashTipsStyles from './cashTips.module.css';

const CashTips = ({
  outlets,
  history,
  restaurant,
}) => {
  const {
    name: restaurantName,
  } = restaurant;
  const [tipHistory, setTipHistory] = useState([]);
  const isLoginRoute = history.location.pathname === '/';

  return (
    <main className={ !isLoginRoute ? "mt mb" : "full-width"}>
      <div className={classNames(CashTipsStyles.CashTips)}>
        <Form
          className={classNames(FormStyles.Form)}
          onSubmit={(event, { outletId, tip }) => {
            recordCashTip(tip, outletId)
              .then((rsp) => setTipHistory([rsp, ...tipHistory]));
          }}
        >
          <h1
            htmlFor="0"
            className={classNames(CashTipsStyles.CashTipsHeading)}
          >
            Cash Tips
          </h1>

          <Dropdown
            label="Location"
            name="outletId"
            onChange={({ target: { value: outletId } }) => getCashTipHistoryForToday(outletId)
              .then(({ results }) => setTipHistory(results))
            }
            placeholder="location"
            required
          >
            {!!outlets.length && outlets.map(({ id, location }) => (
              <option
                key={id}
                value={id}
              >{`${restaurantName} ${location}`}</option>
            ))}
          </Dropdown>

          <Input
            className={classNames(InputStyles.Input, 'mb')}
            id="0"
            label="Tip"
            name="tip"
            placeholder="10"
            required
            step=".01"
            type="number"
          />

          <Button
            className={classNames(ButtonStyles.CashTipButton)}
            content="Record"
            type="submit"
            variant={Button.VARIANTS.primary}
          />
        </Form>
        <DisplayCashTip data={tipHistory} />
      </div>
    </main>
  );
};

CashTips.defaultProps = {
  restaurants: [{}],
};

export default connect(({ User } = { User: {} }) => {
  const restaurants = User.restaurants || [{ outlets: [{}] }];

  const [ restaurant ] = restaurants;
  const { outlets } = restaurant;

  return {
    outlets,
    restaurant,
  };
})(CashTips);
