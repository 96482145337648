import React, {
  createRef,
} from 'react';
import classnames from 'classnames';

import styles from './style.module.css';


export default class Modal extends React.PureComponent {
  static defaultProps = {
    closeOnEsc: true,
    onClose() {},
    overlay: true,
  };

  ref = createRef();

  onKeyPress = (e) => {
    const {
      closeOnEsc,
      onClose,
    } = this.props;
    switch (e.key) {
      case "Escape":
        if (closeOnEsc) {
          this.ref.current.close();
          onClose(e);
        }
        break;
      default:
        break;
    }
  };

  maybeOpenModal = (open, backdropped) => {
    if (open) {
      let method = backdropped
        ? 'showModal'
        : 'show'
      this.ref.current[method]();
    } else {
      this.ref.current.close();
    }
  };

  componentDidMount() {
    const {
      backdropped,
      open,
    } = this.props;

    this.maybeOpenModal(open, backdropped);
  }

  componentDidUpdate({ open: previouslyOpen }) {
    const {
      backdropped,
      open,
    } = this.props;

    if (previouslyOpen !== open) this.maybeOpenModal(open, backdropped);
  }

  render() {
    const {
      onKeyPress,
      props: {
        backdropped,
        children,
        onClose,
      },
    } = this;

    return (
      <dialog
        className={classnames(styles.Modal, {
          [styles.backdropped]: backdropped,
        })}
        ref={this.ref}
        onClick={onClose}
        onKeyPress={onKeyPress}
      >
        {children}
      </dialog>
    );
  }
}
