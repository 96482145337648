import {
  addReducer,
} from '../../store';
import apiClient from "../../apiClient";


export const STAFF_CSV_IMPORTED = 'STAFF_CSV_IMPORTED';
export const STAFF_CSV_IMPORT_FAILURE = 'STAFF_CSV_IMPORT_FAILURE';

export function uploadStaff(body, dispatch, { restaurant }) {
  return apiClient
    .post(`restaurants/${restaurant.id}/register_staff`, {
      body
    })
    .json()
    .then(response => dispatch({
      payload: {
        ...response,
      },
      type: STAFF_CSV_IMPORTED,
    }))
    .catch(({ response: { payload: { errors }} }) => {
      throw dispatch({
        payload: { errors },
        type: STAFF_CSV_IMPORT_FAILURE,
      });
    });
}

const actionHandlersMap = {
  [STAFF_CSV_IMPORTED]: null,
  [STAFF_CSV_IMPORT_FAILURE]: null,
};

addReducer('Staff', actionHandlersMap);
