import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Select from 'react-select';

import styles from './index.module.css';


const MultiSelectDropdown = ({
  className,
  label,
  name,
  placeholder,
  options,
  value,
  getOptionValue,
  formatOptionLabel,
  onChange,
  disabled,
}) => (
  <label
    className={classNames(
      styles.MultiSelectDropdownLabel,
      'mt mb',
    )}
  >
    <span>{label}</span>
    <div
      className={classNames(
        className,
        styles.MultiSelectDropdown,
      )}
    >
      <Select
        isMulti
        value={value}
        name={name}
        placeholder={placeholder}
        options={options}
        value={value}
        getOptionValue={getOptionValue}
        formatOptionLabel={formatOptionLabel}
        onChange={onChange}
        disabled={disabled}
      />
    </div>
  </label>
);

MultiSelectDropdown.propTypes = {
  className: PropTypes.string,
  label: PropTypes.string,
  name: PropTypes.string,
  placeholder: PropTypes.string,
  options: PropTypes.array,
  value: PropTypes.array,
  getOptionValue: PropTypes.func,
  formatOptionLabel: PropTypes.func,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,  
}

export default MultiSelectDropdown;
